const getLabel = (name: string, level: string) => {
  return `${name} – ${level}`
}

export const mapDepartmentVacanciesData = (
  data?: TVacancyList
): TOption[] | null => {
  if (!data) {
    return null
  }

  const availableVacancies = data.map((department): TOption[][] => {
    return department.vacancies.map((item) => {
      return item.levels.map((level) => ({
        value: department.departmentName,
        label: getLabel(item.title, level),
      }))
    })
  })

  return Array.from(new Set(availableVacancies.flat(Infinity))) as TOption[]
}
