import React from 'react'
import { RootLayout } from 'src/layouts'
import { useDimensions, breakpoints } from 'src/ui/dimensions'
import { Meta } from 'src/features/seo'
import { Query } from 'hr/graphql-types'
import { graphql } from 'gatsby'
import { Intro } from 'src/features/referral'
import { BonusSteps, Reward, ReferralForm } from 'src/features/referral'
import { bonusesStepsData } from 'src/features/referral/utils'
import { HBox } from '../../ui/layout'
import { getVacanciesByDepartments } from 'src/shared/selectors'
import { useGetVacancies } from 'src/entities/vacancies/hooks'
import { mapDepartmentVacanciesData } from 'src/entities/leads/mappers'

type Props = {} & { data: Query }

export const Referral = ({ data }: Props) => {
  const { isMobile } = useDimensions()

  const vacanciesData = useGetVacancies()
  const departmentVacanciesData = getVacanciesByDepartments(vacanciesData)
  const vacanciesNames = mapDepartmentVacanciesData(
    departmentVacanciesData?.vacanciesByDepartments
  )

  const formRef = React.useRef<HTMLDivElement>(null)
  const rewardRef = React.useRef<HTMLDivElement>(null)

  const onScrollToSection = (sectionRef: React.RefObject<HTMLDivElement>) => {
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const images = data.allFile.edges.reduce<TImages>((acc, img) => {
    return {
      ...acc,
      [img.node.name]: img.node.childImageSharp?.fluid as TFluidObject,
    }
  }, {})

  return (
    <RootLayout>
      <Meta
        title="Реферальная программа"
        description="Приведи друга в KODE 
      — получи денежное вознаграждение!"
      />
      <Intro
        images={images}
        onScrollToForm={() => onScrollToSection(formRef)}
        onScrollToReward={() => onScrollToSection(rewardRef)}
      />
      <HBox height={isMobile ? 48 : 74} />
      <BonusSteps steps={bonusesStepsData} />
      <div ref={rewardRef} id="reward">
        <HBox height={isMobile ? 48 : 74} />
        <Reward />
      </div>
      <HBox height={isMobile ? 48 : 148} />
      <div ref={formRef}>
        <ReferralForm vacancies={vacanciesNames} />
      </div>
      <HBox height={isMobile ? 48 : 82} />
    </RootLayout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/referral/i" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 1900) {
              srcSet
              base64
              src
              aspectRatio
            }
          }
        }
      }
    }
  }
`

export default Referral
