import { graphql, useStaticQuery } from 'gatsby'

export const useGetVacancies = () =>
  useStaticQuery<GatsbyTypes.dataAndDataQuery>(graphql`
    query dataAndData {
      strapi {
        vacancies(where: { status: "publish" }) {
          id
          order
          slug
          title
          hot
          regions {
            name
            id
          }
          excerpt
          department {
            name
            id
            description
            order
          }
          conditions {
            name
            id
          }
          levels {
            id
            name
          }
        }
      }
    }
  `)
